.pc {
    display: none;
}

.mobile {
    display: flex;
}

@media screen and (min-width: 480px) {
    .chart {
        margin-left: 23%;
        margin-right: 23%;
    }
}

@media screen and (min-width: 480px) {
    .mobile {
        display: none;
    }
}

@media screen and (min-width: 480px) {
    .pc {
        display: block;
        min-height: fit-content;
    }
}
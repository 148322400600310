@import "../../Config/variables";

.card{
    border-radius: 20px;
    height: 100px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid rgb(224, 220, 211);
    box-shadow: rgb(153 147 136 / 20%) 0px 4px 12px;
    background-color: #FFF;

    &.colored > .numerous{
        color: $accent500;        
    }

    .numerous{
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 0;

    }
}
@import '../../Config/_variables.scss';

@keyframes animation-leave-ibani {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .information-leave-ibani {
    p{
      font-size: 10px !important;
    }

    img {
      width: 50vw !important;
      max-width: 50vw !important;
      height: 50vw !important;
      max-height: 50vw !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .information-leave-ibani {
    p{
      font-size: 12px !important;
    }

    img {
      width: 40vw !important;
      max-width: 40vw !important;
      height: 40vw !important;
      max-height: 40vw !important;
    }
  }
}

.information-leave-ibani{
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 200;
  overflow: hidden;

  img{
    height: 30vh;
    width: 30vw;
    max-height: 30vh;
    max-width: 30vw;
    aspect-ratio: 1 / 1;
  }

  p{
    font-size: 20px;
    text-align: center;
    margin: 10px 20px;
    font-weight: 600;
    color: rgb(119, 195, 238);
  }

  .loader{
    height: 10px;
    width: 70vw;
    border-radius: 8px;
    .front{
      border-radius: 8px;
      background-color: $accent500;
      content: ' ';
      height: 100%;
      width: 0%;
      animation: animation-leave-ibani 3s ease-in-out infinite;
    }
  }

}
@import "../Config/variables";

@media only screen and (max-width: 830px){
    .result-page{
        .cards-container {
            flex-direction: column;
            text-align: center;
        
            & > *{
                width: 90%;
                height: 120px;
                margin-bottom: 15px;
                margin-left: 0px !important;
            }
        }
    }
}

.result-page {
    overflow: hidden;

    .content {
        margin: 25px 12vw;
    }

    .cards-container {
        display: flex;
        align-items: center;
        margin: 0px 12vw;


         & > *{
             flex-grow: 1;
             flex-basis: 0;
             margin-left: 25px;
         }

         margin-bottom: 25px;
    }

    .flex-div {
        display: flex;
        flex-direction: column;
        margin: 25px 12vw;
    }

    .uncentered {
        display: flex;
        align-items: center;

    }

    .image-person {
        height: 60px;
        width: 60px;
        border-radius: 50px;
        margin-right: 25px;
    }

    .welcome-text {
        font-size: 17px;
        margin-top: 25px;
    }

    .title-simulation-results {
        font-size: 24px;
        text-decoration: underline;
    }

    .we-will-call-you {
        display: flex;
        align-items: center;

        background-color: #4986e020;
        border-radius: 10px;
        padding: 0 15px;
        max-width: 900px;
        margin-top: 15px;
        margin-bottom: 15px;

        p {
            font-size: 16px;
            margin-left: 10px;
            width: 96%;
            flex-shrink: 0;
            word-break: normal;
        }
    }

    .steps-title {
        text-decoration: underline;
        font-size: 22px;
    }

    .step {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .line1 {
            display: flex;
            align-items: center;

            .icon-number {
                flex-shrink: 0;
                height: 30px;
                width: 30px;
                border-radius: 30px;
                background-color: #000;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                font-weight: 600;
            }

            p {
                font-weight: 600;
                font-size: 18px;
            }
        }

        .line2 {
            margin-left: 45px;
            font-size: 16px;
        }

        &.colored {
            .line1, .line2 {
                color: $accent500;

                .icon-number {
                    background-color: $accent500;
                }

                p {
                    color: $accent500;
                }
            }
        }
    }

    .text-assurance {
        margin: 25px 12vw;
        color: rgb(143, 143, 143);
    }

    .footer {
        margin-top: 30px;
        width: 100%;
        height: 70px;
        background-color: $accent500;
    }

}